<template>
  <div id="main-content-roles-and-permission">
    <roles-table></roles-table>
    <permissions-table></permissions-table>
  </div>
</template>

<script>
import rolesTable from "./tables/RolesTable";
import permissionsTable from "./tables/PermissionsTable";
export default {
  name: "RolesAndPermissions",
  components: {
    rolesTable,
    permissionsTable,
  },
};
</script>
